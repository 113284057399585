import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import game from "../../Assets/Projects/slide3.png";
import fast from "../../Assets/Projects/fast.png";
import sinfeahan from "../../Assets/Projects/sinfeahan.png";
import face from "../../Assets/Projects/face.png";
import done from "../../Assets/Projects/done.png";
import pro1 from "../../Assets/Projects/Project1.png";
import pro2 from "../../Assets/Projects/Project2.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pro2}
              isBlog={false}
              title="Building A Scalable And Resilient Three-tier Architecture On Azure Cloud"
              description="This beginner-friendly guide empowers you with Azure’s Infrastructure as a Service (IaaS) offerings. We’ll embark on an enlightening adventure to master scalable web application deployment using Azure’s cutting-edge services achieving high availability, and Fault Tolerance."
              ghLink="https://medium.com/@asafder902/building-a-scalable-and-resilient-three-tier-architecture-on-azure-cloud-78dc35c6c7fe"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pro1}
              isBlog={false}
              title="Hosting Website On Azure Storage By Implementing CI/CD"
              description="In this beginner-friendly blog post, I will be walking you through the steps it took me to host a static website on an Azure Storage Account and automate the continuous integration and deployment (CI/CD) using Azure DevOps."
              ghLink="https://muhammadalidev.notion.site/Unleashing-the-Power-of-Azure-Storage-A-Beginner-s-Guide-to-Hosting-Static-Websites-on-Azure-Cloud-a8076704ce2e41b1a7a35d8d874648e5"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sinfeahan}
              isBlog={false}
              title="SinfeAhan"
              description="Focused on building a platform specifically
              designed for 80 million Pakistani women,
              enabling them to easily list their skills and
              receive offers from the community."
              ghLink="https://github.com/muhammadali28"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={done}
              isBlog={false}
              title="Done With It"
              description="Developed a full stack app with 2 members
              using the desired tech stack, and containerize
              it using Docker.
              •Set up 2 Docker Swarm cluster on Digital
              Ocean, consisting of multiple nodes for
              running the app instances."
              ghLink="https://github.com/muhammadali28/DoneWithIt"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fast}
              isBlog={false}
              title="Fast Linkedin"
              description="•Created a web application with my 2 peers,
              where employers can post job openings on their
              company profile.
              •Enable job seekers to easily find and search
              for their jobs in 10+ fields based on their
              preferred companies."
              ghLink="https://github.com/muhammadali28/Full-Stack-Online-Job-Portal"            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={game}
              isBlog={false}
              title="Slide Snatch 3D Game"
              description="It's a simple and amazing game for children of all ages where you have to catch the balls on your basket and gain points but you only have three lives for it so be aware of that , the more you catch the balls, the more score and fun you got!"
              ghLink="https://github.com/muhammadali28/Slide-Snatch-3D-Game"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={face}
              isBlog={false}
              title="Face-Recognition-System"
              description="Using 'Natural Language Processing' for the detection of faces in a recognition and attendance system, which could be widely used in school classrooms or offices for attendance tracking."
              ghLink="https://github.com/muhammadali28/Face-Recognition-System"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
