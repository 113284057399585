import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CertiCard from "./CertiCard";
import Particle from "../Particle";
import maa from "../../Assets/Certifications/azure adminsitrator.png";
import ada from "../../Assets/Certifications/ada.png";
import msft from "../../Assets/Certifications/msft3.png";
import az from "../../Assets/Certifications/AZ900.png";
import kk from "../../Assets/Certifications/kk.png";
import fcc from "../../Assets/Certifications/fcc.png";
import dc from "../../Assets/Certifications/datacamp.png";
import piaic from "../../Assets/Certifications/pic1.png";

function Certificates() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Certifications </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few Certifications I've got recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={maa}
              isBlog={false}
              title="Microsoft Azure Administrator Associate"
              ghLink="https://learn.microsoft.com/en-us/users/muhammadali-5362/credentials/c0402ccc8b502a0"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={ada}
              isBlog={false}
              title="Microsoft Azure Developer Associate"
              ghLink="https://www.credly.com/badges/691638e5-5e4f-4a30-81b9-75a9976082ab/linked_in_profile"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={az}
              isBlog={false}
              title="Microsoft Azure Fundamentals"
              ghLink="https://www.credly.com/badges/113827bf-7345-4e34-b536-39ecf33f1b74?source=linked_in_profile"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={msft}
              isBlog={false}
              title="Microsoft DevOps Academy Graduate"
              ghLink="https://cloudsociety.microsoft.com/Certifications/Muhammad%20Ali_2462022340270.pdf"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Azure Kubernetes Service"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-2DF16E19FCDC-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Shell Scripting"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DC5F293-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Docker"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DC70725-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Kubernetes"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DD4E8A4-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={piaic}
              isBlog={false}
              title="Diploma In Artificial Intelligence"
              ghLink="https://certification.piaic.org/AIC024188"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Jenkins"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-8601A9AF58-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Ansible"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DC668EC-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Terraform"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DD5D179-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Linux"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DC5F489-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={kk}
              isBlog={false}
              title="Chef"
              ghLink="https://kodekloud.com/certificate-verification/7C93CB8E21-7C8DC6E074-7C8D987D79/"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={fcc}
              isBlog={false}
              title="Javascript Algorithm & Data Structure"
              ghLink="https://freecodecamp.org/certification/fccdb7960d8-5ef1-41a1-9bb5-3454aff28ad2/javascript-algorithms-and-data-structures"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={dc}
              isBlog={false}
              title="Python"
              ghLink="https://www.datacamp.com/statement-of-accomplishment/course/54f168d247acaf52208e20b0cedee657aa2c4298"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={dc}
              isBlog={false}
              title="Shell Scripting"
              ghLink="https://www.datacamp.com/statement-of-accomplishment/course/24b554866f2f33577d88496437ae0f32dc00e21b"
            />
          </Col>
          <Col md={4} className="project-card">
            <CertiCard
              imgPath={dc}
              isBlog={false}
              title="Bash Scripting"
              ghLink="https://www.datacamp.com/statement-of-accomplishment/course/d3d9205a45a5aa0c2e37ce30113a6e7d9f683ddc"
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Certificates;
