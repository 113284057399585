import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import WorkCard from "./WorkCard";
import Particle from "../Particle";
import impulz from "../../Assets/Work/impulz.png";
import arpa from "../../Assets/Work/ARPATECH.png";
import fls from "../../Assets/Work/fls.png";
import gdsc from "../../Assets/Work/gdsc.jpg";
import kk from "../../Assets/Certifications/kk.png";
import lws from "../../Assets/Work/lws.jpg";

function Certificates() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Work Experience </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are some places I've worked recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "2px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={impulz}
              isBlog={false}
              title="DevOps Engineer"
              company="Impulz Technologies LLC"
              date="Sep-2023 --- Present"
              description="Led the design and architecture of 3+cloud solutions, ensuring seamlessintegration for clients.•Orchestrated 5+ migration plans andperformed risk assessments, resultingin the optimized migration ofapplications, servers, and databases.•Write 10+ scripts to automate thework structure of onboarded clientsand to make things easier for them."
            />
          </Col>
        </Row>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={kk}
              isBlog={false}
              title="Junior DevOps Engineer"
              company="Xfusion Corp (A Kodekloud Company)"
              date="May-2023 --- Present"
              description="As xfusion is an imaginary company and it does have several task related to many devops tools which we have to perform on daily basis to score more and push for the above rank in DevOps Department.☁️💪"
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={arpa}
              isBlog={false}
              title="Trainee MSP-DevOps Engineer"
              company="Arpatech"
              date="June-2023 --- Aug-2023"
              description="• On-boarding of client to Azure Lighthouse and different security and compliance tools.
              • Training for the discussion with International Clients to discover their On-Prem environment.
              • Training for the ITSM Tool like Zendesk.
              • Managing the cloud solution design and architect for the customer.
              • Working on Migration Plans and Risk Assessments for the customers.
              • Cloud resource and Application migration optimization.
              • Server and Database migration and optimization.
              • Automated routine task and documentation."
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={gdsc}
              isBlog={false}
              title="Team Lead Cloud"
              company="Google Developer Student Club"
              date="Oct-2022 --- Aug-2023"
              description="For the past year, I had the privilege of serving as the GDSC (Google Developer Student Clubs) Cloud Lead. In this role, I successfully led a team of 5 people while overseeing a larger group of over 50 members. It has been an incredibly rewarding experience as I organized and hosted multiple workshops and labs focused on educating participants about cloud computing, with a specific emphasis on providing them with a strong foundation in the fundamentals of Microsoft Azure Cloud."
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={lws}
              isBlog={false}
              title="Software Developer - Internship"
              company="Lucid Web Solutions"
              date="Jul-2022 --- Oct-2022"
              description="I worked as a Software Developer Intern for 3 months and actively contributed to various projects within the company. Notably, I played a significant role in building the Lucid HRM product from scratch. During my internship, I gained hands-on experience in a range of technologies, including React.js, HTML, CSS, and JavaScript for front-end development, as well as Node.js, SQL, Sequelize for back-end development. Additionally, I worked with PHP for additional functionalities."
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={10} className="project-card">
            <WorkCard
              imgPath={fls}
              isBlog={false}
              title="Web Developer"
              company="Fast Lead Solution"
              date="May-2021 --- Oct-2021"
              description="I worked as a Web Developer for 6 months and made significant contributions to the development of the company's official website. During this period, I gained hands-on experience in various tech stacks, including React, Node.js, HTML, CSS, and Wordpress. Additionally, I was responsible for deploying the website."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Certificates;
