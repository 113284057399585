import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Particle from "../Particle";
import azgcp from "../../Assets/Blogs/1.jpg";
import game from "../../Assets/Blogs/4.png";
import fast from "../../Assets/Blogs/2.jpg";
import bigdata from "../../Assets/Blogs/7.png";
import face from "../../Assets/Blogs/5.png";
import done from "../../Assets/Blogs/3.jpg";
import pro1 from "../../Assets/Blogs/project1.png";
import opt from "../../Assets/Blogs/optimizing.png";
import pro2 from "../../Assets/Blogs/Project2.gif";

function Blogs() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Blogs </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few Blogs I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <BlogCard
              imgPath={pro2}
              isBlog={false}
              title="Building A Scalable And Resilient Three-tier Architecture On Azure Cloud"
              description="This beginner-friendly guide empowers you with Azure’s Infrastructure as a Service (IaaS) offerings. We’ll embark on an enlightening adventure to master scalable web application deployment using Azure’s cutting-edge services achieving high availability, and Fault Tolerance."
              ghLink="https://medium.com/@asafder902/building-a-scalable-and-resilient-three-tier-architecture-on-azure-cloud-78dc35c6c7fe"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={opt}
              isBlog={false}
              title="Optimizing Azure Workloads: Automate Vm Startup And Shutdown"
              description="This introductory exploration delves into the importance of automating VM startup and shutdown within Azure, highlighting its benefits and providing insights into best practices for optimization."
              ghLink="https://impulztech.com/optimizing-azure-workloads-automate-vm-startup-and-shutdown/"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <BlogCard
              imgPath={pro1}
              isBlog={false}
              title="Hosting Website On Azure Storage By Implementing CI/CD"
              description="In this beginner-friendly blog post, I will be walking you through the steps it took me to host a static website on an Azure Storage Account and automate the continuous integration and deployment (CI/CD) using Azure DevOps."
              ghLink="https://muhammadalidev.notion.site/Unleashing-the-Power-of-Azure-Storage-A-Beginner-s-Guide-to-Hosting-Static-Websites-on-Azure-Cloud-a8076704ce2e41b1a7a35d8d874648e5"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={azgcp}
              isBlog={false}
              title="How To Migrate From Azure To GCP"
              description="Have you ever wondered how to switch things up in the cloud world? 🤔 My blog has the lowdown on moving your stuff from Azure to Google Cloud Platform (GCP). It's like changing houses but for your data! 🏠📦

              I break it down into simple steps, making it easy for you to understand. Let's face it, We all love a good makeover, even for our cloud setup! ☁️💪"
              ghLink="https://muhammadalidev.notion.site/How-to-Migrate-From-Azure-To-GCP-4cb5385cf03d4fca813b8e47c7daa43a?pvs=4"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <BlogCard
              imgPath={bigdata}
              isBlog={false}
              title="Big Data Capabilities Of Azure Synapse, Databricks And Data Factory"
              description="In a world driven by data, harnessing its power efficiently is key. My latest blog delves into the dynamic trio – Azure Synapse, Databricks, and DataFactory, exploring how these tools empower businesses to unlock the true potential of their data. 📊 "
              ghLink="https://impulztech.com/big-data-capabilities/"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={done}
              isBlog={false}
              title="How To Setup An Organization In GCP"
              description="Setting up a rock-solid foundation is key, and my latest blog takes you through the intricate process of creating a robust organization within the Google Cloud Platform. 🏢 From defining roles and permissions to structuring your projects effectively, this guide is a must-read for anyone looking to harness the power of GCP to its fullest."
              ghLink="https://muhammadalidev.notion.site/How-To-Setup-An-Organization-In-GCP-245eb51265ea46c1af04578aecdb2bdb"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={fast}
              isBlog={false}
              title="How To Migrate Projects Between Different Organization In GCP"
              description="Have you ever wondered about the best way to migrate projects between different GCP organizations? Look no further! My second blog delves into the art of project migration, providing step-by-step instructions, best practices, and valuable insights. Whether optimizing resource allocation or streamlining workflows, this guide has you covered.
              "
              ghLink="https://muhammadalidev.notion.site/How-To-Migrate-Projects-Between-Different-Organizations-In-GCP-44082227b96d4d699bbd795e2f44096b"            
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={game}
              isBlog={false}
              title="How To Setup Connection Between Azure DevOps & LCS"
              description="This blog delves into the essential steps to connect Azure DevOps with Lifecycle Services (LCS) in a world where seamless collaboration is key. This integration can be a game-changer for your project management and development processes."
              ghLink="https://impulztech.com/how-to-setup-a-connection-between-azure-devops-lcs/"
            />
          </Col>

          <Col md={4} className="project-card">
            <BlogCard
              imgPath={face}
              isBlog={false}
              title="Create Build & Development Server In Dynamics 365 F&O Part-1"
              description="Part 1 reveals the setup of getting your build and development server up and running, while Part 2 (coming soon) will guide you through the steps of deploying your build artifact to a selected LCS environment. 🛠️

              This is a must-read if you're looking to streamline your development process and ensure smooth deployments! Don't miss out on this valuable resource."
              ghLink="https://impulztech.com/create-build-development-server-d365-finance-operations-part-1/"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
          <Col md={4} className="project-card">
            <BlogCard
              imgPath={face}
              isBlog={false}
              title="Setting up a Release Pipeline In D365 FO Part-2"
              description="Part 1 reveals the setup of getting your build and development server up and running, while Part 2 will guide you through the steps of deploying your build artifact to a selected LCS environment. 🛠️

              This is a must-read if you're looking to streamline your release process and ensure smooth deployments to Production! Don't miss out on this valuable resource."
              ghLink="https://impulztech.com/setup-a-release-pipeline-in-d365-fo-part-2/"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Blogs;
