import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            Venturing into the dynamic realm of DevOps, I'm Muhammad Ali – a seasoned <i><b className="purple">DevOps Engineer</b></i> on a mission to optimize and streamline the software development lifecycle.
              <br />
              <br />
              My proficiency extends across a spectrum of tools and technologies, from <i><b className="purple">Azure</b></i> and <i><b className="purple">AWS</b></i> to <i><b className="purple">Kubernetes</b></i> and <i><b className="purple">Terraform</b></i>. Leveraging this expertise, I've led the design and architecture of multiple cloud solutions, overseeing migration plans and executing risk assessments to ensure optimal performance.I've automated workflows by scripting, making onboarding processes seamless for clients. I've simplified complex tasks, enhancing operational efficiency and reducing manual interventions.
              <br />
              <br />
              Let's <i><b className="purple">Collaborate</b></i> to navigate the intricate landscapes of DevOps, embracing innovation and efficiency in every step of the software development process!
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/muhammadali28"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/m-ali-dev/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/m_ali2820/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
