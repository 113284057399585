import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsLink45Deg } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";

function WorkCard(props) {
  return (
    <Card className="project-card-view py-4"  >
      <Row>
        <Col md={4}>
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />
        </Col>
        <Col md={8}>
            <Card.Body style={{ textAlign: "left" }}>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>{props.company}</Card.Text>
                <Card.Text>{props.date}</Card.Text>
                <Card.Text>
                {props.description}
                </Card.Text>
                {/* <Button variant="primary" href={props.ghLink} target="_blank">
                <BsLink45Deg /> &nbsp;
                {props.isBlog ? "Blog" : "Blog Link"}
                </Button> */}
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

                {!props.isBlog && props.demoLink && (
                <Button
                    variant="primary"
                    href={props.demoLink}
                    target="_blank"
                    style={{ marginLeft: "10px" }}
                >
                    <CgWebsite /> &nbsp;
                    {"Demo"}
                </Button>
                )}
            </Card.Body>
        </Col>
      </Row>
      
    </Card>
  );
}
export default WorkCard;
